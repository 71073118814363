import React from "react";
import Head from "next/head";
import { SaleorProvider } from "@saleor/sdk";
import { ApolloProvider, createHttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { AppWrapper } from "../app";
import { NextQueryParamProvider } from "../components/NextQueryParamProvider";
import { StoreWrapper as Provider } from "../context/state";
import { IntlProvider } from "react-intl";
import { setContext } from '@apollo/client/link/context';
import { apiUrl, scriptUrl, locale, channelSlug } from "../constants";
import messages_ru from "../../locale/ru.json"
import messages_en from "../../locale/en.json"
import messages_es from "../../locale/es.json"

const messagesDict = {
    "ru": messages_ru,
    "en": messages_en,
    "es": messages_es
}

import "../globalStyles/scss/index.scss";

const appToken = ""

const saleorConfig = {
  apiUrl,
 channel: channelSlug
};

const httpLink = createHttpLink({
  uri: apiUrl,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: appToken ? `Bearer ${appToken}` : "",
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});


function App({ Component, pageProps }) {

    const scriptUrlSplit = scriptUrl.split("|")
    let scriptUrlProcessed = scriptUrlSplit.length === 2 ? (scriptUrlSplit[1] + "." + scriptUrlSplit[0]) : scriptUrl
    if (!scriptUrlProcessed.startsWith("http")) {
        scriptUrlProcessed = "https://" + scriptUrlProcessed
    }

  return (
    <>
      <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <link rel="preconnect" href={apiUrl} />
          <link rel="preconnect" href={scriptUrlProcessed} />
      </Head>
      <NextQueryParamProvider>
        <ApolloProvider client={client}>
          <IntlProvider locale={locale} defaultLocale="en" messages={messagesDict[locale]} key={locale} >
            <Provider>
              <SaleorProvider config={saleorConfig}>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </SaleorProvider>
            </Provider>
          </IntlProvider>
        </ApolloProvider>
      </NextQueryParamProvider>
    </>
  );
}

export default App;
