import React, { useState } from "react";


import { defaultContext, ShopContext } from "./context";


const ShopProvider = ({ shopConfig, children }) => {
  const [context] = useState({
    ...defaultContext,
    ...shopConfig,
  });

  return (
    <ShopContext.Provider value={context}>{children}</ShopContext.Provider>
  );
};

export default ShopProvider;
