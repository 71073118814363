import Link from "next/link";
import React, {useState} from "react";

import { paths } from "../../paths";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "../../intl";


const BehaviourModal = () => {
    const [loading, setLoading] = useState(false)

    if (loading) {
        return (
            <div id="jsModal" className={"custom-modal open"}>
                <div className={"custom-modal__overlay"}></div>
                <div className={"custom-modal__container"}>
                    <div id="lds-dual-ring"></div>
                    <span style={{marginTop:15, fontSize: 18}}><FormattedMessage {...commonMessages["loading"]} />...</span>
                </div>
            </div>
        )
    }
    return (
        <div id="jsModal" className={"custom-modal open"}>
            <div className={"custom-modal__overlay"}></div>
            <div className={"custom-modal__container"}>
                <div className={"custom-modal__header"}>
                    <div>
                    </div>

                    <svg    xmlns="http://www.w3.org/2000/svg"
                            width="32px"
                            height="32px"
                            viewBox="0 0 24 24"
                            className="cldiaction"
                            onClick={() => setLoading(true)}
                            fill="none">
                        <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z"
                              fill="black"/>
                    </svg>
                </div>
                <div className={"custom-modal__body"}>
                    <FormattedMessage {...commonMessages["cookieText"]} />

                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{marginTop: 20}} viewBox="0 0 512 512" width="70px" height="70px">
                        <g transform="translate(1 1)">
                            <g>
                                <g>
                                    <path d="M507.587,214.893c-0.853-3.413-3.413-5.973-6.827-6.827c-3.413-0.853-6.827,0.853-8.533,3.413     c-11.093,16.213-29.867,26.453-49.493,26.453c-21.333,0-41.813-11.093-52.053-29.867c-1.707-2.56-5.12-4.267-8.533-4.267     c-2.56,0-5.12,0-7.68,0c-42.667,0-76.8-34.133-76.8-76.8c0-7.467,1-14.6,3-21.4c1.32-4.2,2.76-8.04,4.68-11.88     c0.853-1.707,0.853-4.267,0-6.827c-0.853-2.56-2.56-4.267-5.12-5.12c-17.067-5.973-28.16-22.187-28.16-40.107     c0-1.323,0.059-2.631,0.172-3.926c0.121-0.901,0.323-1.825,0.682-2.901c0-0.592-0.002-1.184-0.023-1.766     c1.346-6.956,4.295-13.48,8.556-19.568c2.56-2.56,2.56-5.973,1.707-8.533c-0.853-2.56-3.413-5.12-6.827-5.12     C268.653-1,261.827-1,255-1C114.2-1-1,114.2-1,255s115.2,256,256,256s256-115.2,256-256     C511,241.347,509.293,228.547,507.587,214.893z M283.569,103.396c-5.069,3.314-11.744,6.537-20.036,6.537     c-18.773,0-34.133-15.36-34.133-34.133c0-16.213,11.093-29.867,25.6-33.28c0,22.187,12.8,41.813,31.573,52.053     C285.374,97.453,284.383,100.401,283.569,103.396z M255,493.933c-131.413,0-238.933-107.52-238.933-238.933     S123.587,16.067,255,16.067c1.707,0,3.413,0,5.973,0c-1.551,2.792-2.76,5.81-3.675,8.929     c-25.246,3.102-44.965,24.755-44.965,50.804c0,28.16,23.04,51.2,51.2,51.2c5.804,0,11.605-1.016,17.115-3.03     c-0.029,1.01-0.048,2.019-0.048,3.03c0,52.053,41.813,93.867,93.867,93.867c1.707,0,2.56,0,4.267,0     c14.507,21.333,38.4,34.133,64,34.133c18.773,0,36.693-6.827,50.347-18.773c0.853,5.973,0.853,12.8,0.853,18.773     C493.933,386.413,386.413,493.933,255,493.933z"/>
                                    <path d="M144.067,135.533c-28.16,0-51.2,23.04-51.2,51.2s23.04,51.2,51.2,51.2c28.16,0,51.2-23.04,51.2-51.2     S172.227,135.533,144.067,135.533z M144.067,220.867c-18.773,0-34.133-15.36-34.133-34.133c0-18.773,15.36-34.133,34.133-34.133     c18.773,0,34.133,15.36,34.133,34.133C178.2,205.507,162.84,220.867,144.067,220.867z"/>
                                    <path d="M152.6,323.267c-23.893,0-42.667,18.773-42.667,42.667S128.707,408.6,152.6,408.6c23.893,0,42.667-18.773,42.667-42.667     S176.493,323.267,152.6,323.267z M152.6,391.533c-14.507,0-25.6-11.093-25.6-25.6s11.093-25.6,25.6-25.6     c14.507,0,25.6,11.093,25.6,25.6S167.107,391.533,152.6,391.533z"/>
                                    <path d="M323.267,297.667c-37.547,0-68.267,30.72-68.267,68.267s30.72,68.267,68.267,68.267c37.547,0,68.267-30.72,68.267-68.267     S360.813,297.667,323.267,297.667z M323.267,417.133c-28.16,0-51.2-23.04-51.2-51.2s23.04-51.2,51.2-51.2     c28.16,0,51.2,23.04,51.2,51.2S351.427,417.133,323.267,417.133z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={"custom-modal__action"}>
                    <span className="custom-modal__button cldiaction" onClick={() => setLoading(true)}>
                        <FormattedMessage {...commonMessages["cookieAccept"]} />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default BehaviourModal;
