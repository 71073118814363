import React, { useEffect, useState } from "react";
import { useStore } from "../context/state";
import ShopProvider from "../components/ShopProvider";
import BehaviourModal from "../components/BehaviourModal";

export const AppWrapper = ({ children, shopConfig }) => {
  const { dispatch } = useStore();

  const onResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isMobile = width < 800;
    dispatch("style", { width, height, isMobile });
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);


  return (
    <ShopProvider shopConfig={shopConfig}>
        <div id="ovrl-div-ovrl">
            <BehaviourModal/>
        </div>
        {children}
    </ShopProvider>
  );
};
